import { useManufacturerIdFromSession } from '../../../../../utils/authorization';
import { orderIdFromQRCodeUrl } from '../../../../../utils/utils';
import { type MutationResult, type SubmitArgs, useMutation } from './BookingForm.graphql';
import * as BookingForm from './BookingForm.vm';
import { type Scan, useViewModel as useScanListViewModel } from './components/ScanList.vm';
import { type ApolloError } from '@apollo/client';
import { type StaffMemberSession, useSession } from '@orthly/session-client';
import {
    getDepartmentOptionsForLab,
    getMachineIdOptionsForDepartment,
    getStageOptionsForDepartment,
} from '@orthly/shared-types';
import { apolloErrorMessage, type SimpleSelectOption } from '@orthly/ui';
import type { RadioGroupProps, SelectChangeEvent } from '@orthly/ui-primitives';
import { type EnqueueSnackbar, useSnackbar } from 'notistack';
import React from 'react';

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

export function getBookingActionOptions(): RadioGroupProps['options'] {
    return [
        { id: 'ON', label: 'BOOK ON' },
        { id: 'OFF', label: 'BOOK OFF' },
    ];
}

export function getDepartmentOptions(mfgId: string | undefined): SimpleSelectOption[] {
    const nameById = getDepartmentOptionsForLab(mfgId);
    const entries = Object.entries(nameById);
    const opts = entries.map(([id, name]) => ({ label: name, value: id }));
    return opts.sort((a, b) => a.label.localeCompare(b.label));
}

export function getMachineIdOptions(department: string | undefined, mfgId: string | undefined): SimpleSelectOption[] {
    const machines = getMachineIdOptionsForDepartment(department, mfgId);
    const opts = Object.entries(machines).map(([id, name]) => ({ label: name, value: id }));
    return opts.sort((a, b) => a.label.localeCompare(b.label));
}

export function getStageOptions(department: string | undefined, mfgId: string | undefined): SimpleSelectOption[] {
    const stages = getStageOptionsForDepartment(department, mfgId);
    const opts = stages.map(stage => ({ label: stage, value: stage }));
    return opts.sort((a, b) => a.label.localeCompare(b.label));
}

export function getUserName(session: StaffMemberSession | undefined): string | undefined {
    if (!session) {
        return undefined;
    }
    return `${session.user.first_name} ${session.user.last_name}`;
}

export function isDisabled(
    bookingAction: string,
    department: string,
    machineId: string | undefined,
    mfgId: string | undefined,
    stages: string[],
): boolean {
    if (bookingAction.trim() === '' || department.trim() === '') {
        return true;
    }
    if (stages.length === 0) {
        return true;
    }
    const departments = BookingForm.getDepartmentOptions(mfgId).map(i => i.value);
    if (departments.includes(department) === false) {
        return true;
    }
    if (machineId) {
        const opts = BookingForm.getMachineIdOptions(department, mfgId);
        const values = opts.map(i => i.value);
        if (values.includes(machineId) === false) {
            return true;
        }
    }
    return false;
}

export function onError(enqueueSnackbar: EnqueueSnackbar, error: ApolloError): void {
    const message = apolloErrorMessage(error);
    enqueueSnackbar(message, { variant: 'error' });
}

export function onSuccess(enqueueSnackbar: EnqueueSnackbar) {
    enqueueSnackbar('Booking complete.', { variant: 'success' });
}

export function setBookingAction(setState: SetState<string>, value: string): void {
    setState(value.trim());
}

export function setDepartment(
    setDepartment: SetState<string>,
    setMachineId: SetState<string | undefined>,
    setStages: SetState<string[]>,
    value: string | undefined,
): void {
    const v = value ?? '';
    setDepartment(v.trim());
    setMachineId(undefined);
    setStages([]);
}

export function setIsOvertime(setState: SetState<boolean>, value: boolean): void {
    setState(value);
}

export function setMachineId(setState: SetState<string | undefined>, value: string | undefined): void {
    const v = value ? value.trim() : undefined;
    setState(v);
}

export function setStages(setState: SetState<string[]>, value: string | string[]): void {
    const v = typeof value === 'string' ? [value] : value;
    setState(v);
}

export function submit(
    args: {
        bookingAction: string;
        department: string;
        isOvertime: boolean;
        machineId: string | undefined;
        orderUrl: string;
        stages: string[];
    },
    hooks: {
        addScan: (v: Scan) => void;
        enqueueSnackbar: EnqueueSnackbar;
        mutation: (args: SubmitArgs) => void;
    },
): void {
    const { bookingAction, department, isOvertime, machineId, orderUrl, stages } = args;
    const { addScan, enqueueSnackbar, mutation } = hooks;

    const orderId = orderIdFromQRCodeUrl(orderUrl);

    if (!orderId) {
        enqueueSnackbar('Please scan a valid QR code.', { variant: 'error' });
        return;
    }

    const scan = {
        bookingAction,
        bookingTime: new Date(),
        orderId,
        stages,
    };
    addScan(scan);

    mutation({
        bookingAction,
        department,
        isOvertime,
        machineId,
        orderId,
        stages,
    });
}

export function useViewModel() {
    const { enqueueSnackbar } = useSnackbar();
    const mfgId = useManufacturerIdFromSession();
    const session = useSession();

    const { addScan, clearScans, scans } = useScanListViewModel();

    const onError_ = (_: SubmitArgs, e: ApolloError) => onError(enqueueSnackbar, e);
    const onSuccess_ = (_: SubmitArgs, __: MutationResult) => onSuccess(enqueueSnackbar);
    const { submit: submitMutation } = useMutation(onError_, onSuccess_);

    const [bookingAction, setBookingActionHook] = React.useState<string>('');
    const [department, setDepartmentHook] = React.useState<string>('');
    const [isOvertime, setIsOvertimeHook] = React.useState<boolean>(false);
    const [machineId, setMachineIdHook] = React.useState<string | undefined>(undefined);
    const [stages, setStagesHook] = React.useState<string[]>([]);

    const bookingActionOptions = getBookingActionOptions();
    const machineIdOptions = getMachineIdOptions(department, mfgId);
    const stageOptions = getStageOptions(department, mfgId);
    const departmentOptions = getDepartmentOptions(mfgId);
    const userName = getUserName(session);

    const disabled = isDisabled(bookingAction, department, machineId, mfgId, stages);

    const setBookingAction_ = (v: string) => setBookingAction(setBookingActionHook, v);
    const setDepartment_ = (v?: string) => setDepartment(setDepartmentHook, setMachineIdHook, setStagesHook, v);
    const setIsOvertime_ = (v: boolean) => setIsOvertime(setIsOvertimeHook, v);
    const setMachineId_ = (v?: string) => setMachineId(setMachineIdHook, v);
    const setStages_ = (e: SelectChangeEvent<string[]>) => {
        setStages(setStagesHook, e.target.value);
    };
    const submit_ = (v: string) =>
        submit(
            { bookingAction, department, isOvertime, machineId, orderUrl: v, stages },
            { addScan, enqueueSnackbar, mutation: submitMutation },
        );

    return {
        bookingAction,
        bookingActionOptions,
        clearScans,
        department,
        departmentOptions,
        disabled,
        isOvertime,
        machineId,
        machineIdOptions,
        setBookingAction: setBookingAction_,
        setDepartment: setDepartment_,
        setIsOvertime: setIsOvertime_,
        setMachineId: setMachineId_,
        setStages: setStages_,
        scans,
        stages,
        stageOptions,
        submit: submit_,
        userName,
    };
}
