import { useManufacturerIdFromSession } from '../../../../utils/authorization';
import { RequestManufacturerDelay } from '../../actions/manufacturers/DelayActions';
import { RecordInternalRemakeModal } from '../../actions/manufacturers/MarkInternalRemakeModal.graphql';
import { AddOrRemoveHoldButton } from '../../actions/manufacturers/OrderHoldActions';
import { OrderActionsGrid } from './OrderActionsGrid';
import { OrderDetailTrackingUnitButton } from './OrderDetailTrackingActions';
import { OrderDetailTrackingServiceLevelSpeedBanner } from './OrderDetailTrackingServiceLevelSpeedBanner.graphql';
import { useQuery } from '@apollo/client';
import { differenceInBusinessDays } from '@orthly/date-fns-business-days';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlOrder, LabsGqlOrderSlaFragment } from '@orthly/graphql-operations';
import { usePendingReturnForOrderQuery } from '@orthly/graphql-react';
import {
    LabsGqlLabOrderStatus,
    LabsGqlOrderReturnStatus,
    LabsGqlRushRequestStatus,
    LabsGqlWorkflowStateEnum,
} from '@orthly/graphql-schema';
import { OrderItemV2Utils } from '@orthly/items';
import { UuidUtils } from '@orthly/runtime-utils';
import { HoldUtils, ShippingUtils, LEHI_MANUFACTURER_ID } from '@orthly/shared-types';
import { FlossPalette, Button, Grid, Tooltip, Text, Typography } from '@orthly/ui-primitives';
import { OrderDetailBlock, OrderDetailTrackerV2, SlaUtil } from '@orthly/veneer';
import moment from 'moment';
import React from 'react';

const formatDate = (date: string) => moment(date).format('MMM D');
const formatLongDate = (date: string) => moment(date).format('MMMM D');
const formatDateTime = (date: string) => moment(date).format('MMMM D, h:mmA');

interface UseTrackingDetailsArgs {
    order: LabsGqlOrder;
    rushAccepted: boolean;
}

const useTrackingDetails = (args: UseTrackingDetailsArgs) => {
    const { order, rushAccepted } = args;
    const pendingReturnQuery = usePendingReturnForOrderQuery({
        skip: !UuidUtils.isUUID(order.id),
        variables: { orderId: order.id },
    });
    const pendingReturnStatus = pendingReturnQuery.data?.pendingReturnForOrder?.status;
    // EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
    // eslint-disable-next-line sonarjs/cognitive-complexity
    return React.useMemo<{ title: React.ReactNode; date: string; suffix?: React.ReactNode }>(() => {
        if (order.carrier && ShippingUtils.isReturned(order.ship_status)) {
            const carrier = ShippingUtils.getCarrierDisplayText(order.carrier);

            return {
                title: `${carrier} was unable to deliver this. Returning to Dandy`,
                date: '',
                suffix: (
                    <Typography variant={'body2'} color={'textSecondary'}>
                        To reschedule delivery call us at 267-310-3332
                    </Typography>
                ),
            };
        }

        const dueDate = order.manufacturer_sla.due_date;
        switch (order.status) {
            case LabsGqlLabOrderStatus.Delivered:
                return { title: 'Delivery date: ', date: formatDate(order.delivery_date ?? dueDate) };
            case LabsGqlLabOrderStatus.NeedsRefabrication:
                if (pendingReturnStatus) {
                    const message =
                        pendingReturnStatus === LabsGqlOrderReturnStatus.Transit ? 'In transit' : 'Needs shipment';
                    return { title: `Return requested: `, date: message };
                }
                const refabDate = formatDate(order.needs_refabrication_date ?? order.delivery_date ?? dueDate);
                return { title: 'Sent for refabrication: ', date: refabDate };
            case LabsGqlLabOrderStatus.Cancelled:
                return { title: `Canceled`, date: '' };
            case LabsGqlLabOrderStatus.OnHold:
                const title = order.hold_details ? 'On Hold: ' : 'On Hold ';
                const suffix = HoldUtils.composeHoldReason(order.hold_details) ?? '';

                return { title, date: '', suffix };
            case LabsGqlLabOrderStatus.New:
            case LabsGqlLabOrderStatus.Fabrication:
            case LabsGqlLabOrderStatus.Shipped:
            default:
                const originalEta = order.fulfillment.current.shipment?.original_eta;
                const eta = order.fulfillment.current.shipment?.eta;
                if (order.carrier && originalEta && eta && new Date(originalEta) < new Date(eta)) {
                    const carrier = ShippingUtils.getCarrierDisplayText(order.carrier);
                    const latestDeliveryAttempt = order.fulfillment.current.shipment?.latest_delivery_attempt_date;

                    return {
                        title: (
                            <span>
                                {latestDeliveryAttempt
                                    ? `${carrier} will try to deliver again on `
                                    : `${carrier} will try to deliver on `}
                                <span style={{ color: FlossPalette.SECONDARY_FOREGROUND }}>{formatLongDate(eta)}</span>
                            </span>
                        ),
                        suffix: (
                            <Typography variant={'body2'} color={'textSecondary'}>
                                {latestDeliveryAttempt && new Date(latestDeliveryAttempt) < new Date(eta)
                                    ? `${carrier} was unable to deliver this order on ${formatDateTime(
                                          latestDeliveryAttempt,
                                      )}, and will try again on ${formatLongDate(eta)}.`
                                    : `${carrier} has delayed this order, and will attempt delivery on ${formatLongDate(
                                          eta,
                                      )}.`}
                            </Typography>
                        ),
                        date: '',
                    };
                }

                return {
                    title: rushAccepted ? 'Rush due date: ' : 'Due date: ',
                    date: formatDate(dueDate),
                };
        }
    }, [order, pendingReturnStatus, rushAccepted]);
};

const ManufacturerShipByRow: React.VFC<{ manufacturerSla: LabsGqlOrderSlaFragment; attention: boolean }> = ({
    manufacturerSla,
    attention,
}) => {
    const { rush_request } = manufacturerSla;
    const rushAccepted = rush_request?.status === LabsGqlRushRequestStatus.Accepted;
    const shipByDate = SlaUtil.getOrderTrackerShipByDate(manufacturerSla);
    // Get ship dates in EST
    const shipByMoment = moment.tz(shipByDate, 'UTC').tz('America/New_York');
    const mcsbdMoment = moment.tz(manufacturerSla.manufacturer_committed_ship_by_date, 'UTC').tz('America/New_York');
    // If the Ship By Date is greater than the manufacturer_committed_ship_by_date, the Ship By Date includes delays, and we want to let the lab know
    const shipByAdjustmentDays = differenceInBusinessDays(shipByMoment.toDate(), mcsbdMoment.toDate());
    const adjustmentLabel = shipByAdjustmentDays > 0 ? ` (Includes ${shipByAdjustmentDays} day delay)` : '';
    const rushShipByLabel = `Rush ship by${adjustmentLabel}: `;
    const shipByLabel = `Ship By${adjustmentLabel}: `;
    return (
        <Grid container>
            <Text variant={'h5'} style={{ color: attention ? FlossPalette.ATTENTION : undefined }}>
                {rushAccepted ? rushShipByLabel : shipByLabel}
                <span
                    style={{
                        color: FlossPalette.HIGHLIGHT_BLUE,
                    }}
                >
                    {shipByMoment.format('MMM D, YYYY')}
                </span>
            </Text>
        </Grid>
    );
};

const OrderDetailTrackingTitle: React.FC<{ order: LabsGqlOrder }> = props => {
    const { order } = props;

    const rushAccepted = order.manufacturer_sla.rush_request?.status === LabsGqlRushRequestStatus.Accepted;
    const { title, date, suffix } = useTrackingDetails({ order, rushAccepted });

    const onHoldOrCancelled = [LabsGqlLabOrderStatus.OnHold, LabsGqlLabOrderStatus.Cancelled].includes(order.status);

    return (
        <Grid container>
            {order.status === LabsGqlLabOrderStatus.Fabrication && (
                <ManufacturerShipByRow manufacturerSla={order.manufacturer_sla} attention={onHoldOrCancelled} />
            )}
            <Grid container style={{ marginTop: 12 }} direction={'row'}>
                <Text variant={'h5'} data-test={'tracking-title'}>
                    {title}
                    <span
                        style={{
                            color:
                                !order.ship_date &&
                                (SlaUtil.calculateDaysPastDate(order.manufacturer_sla.ship_by_date) > 0 ||
                                    SlaUtil.isManufacturerDueDateDelay(order.manufacturer_sla))
                                    ? FlossPalette.ATTENTION
                                    : FlossPalette.SECONDARY_FOREGROUND,
                        }}
                    >
                        {date}
                    </span>
                    {suffix}
                </Text>
            </Grid>
            {!!order.hold_details && order.status === LabsGqlLabOrderStatus.OnHold && (
                <span style={{ color: FlossPalette.ATTENTION, fontSize: 14, marginTop: 8 }}>
                    Avoid delays by getting back to us as soon as possible.
                </span>
            )}
        </Grid>
    );
};

interface OrderDetailTrackingProps {
    order?: LabsGqlOrder;
    refetch: () => Promise<any>;
    switchToTatProgressView: null | (() => void);
}

const LabPortalOrderDetailTrackingAdditionalData_Query = graphql(`
    query LabPortalOrderDetailTrackingAdditionalData_Query($order_id: String!, $timezone_offset_minutes: Int!) {
        getOrderTrackerEntries(order_id: $order_id, timezone_offset_minutes: $timezone_offset_minutes) {
            active
            alert
            subtitle
            subtitleAttachment
            title
            infoTooltip
            style
        }
        getLabSalesOrderById(id: $order_id) {
            ...LabPortalOrderDetailTrackingNoRushBannerSalesOrder_Fragment
        }
    }
`);

export const OrderDetailTracking: React.FC<OrderDetailTrackingProps> = ({
    order,
    refetch: refetchOrder,
    switchToTatProgressView,
}) => {
    const mfgId = useManufacturerIdFromSession();
    const { data: additionalData, refetch: refetchAdditionalData } = useQuery(
        LabPortalOrderDetailTrackingAdditionalData_Query,
        {
            variables: {
                // We skip if this isn't loaded, so it's safe
                order_id: order?.id ?? '',
                timezone_offset_minutes: new Date().getTimezoneOffset(),
            },
            skip: !order,
            fetchPolicy: 'no-cache',
            nextFetchPolicy: 'no-cache',
        },
    );

    const refetch = async () => Promise.all([refetchOrder, refetchAdditionalData]);

    const detailTrackingUnitButton = React.useMemo(() => {
        return !order ? undefined : <OrderDetailTrackingUnitButton order={order} />;
    }, [order]);

    const isInternalRemakeModalVisible = mfgId === LEHI_MANUFACTURER_ID;

    return (
        <OrderDetailBlock
            title={'Tracking'}
            updatedAt={order?.updated_at}
            variant={'right'}
            actions={
                <>
                    {detailTrackingUnitButton}
                    {order && <RequestManufacturerDelay order={order} />}
                    {order && mfgId && isInternalRemakeModalVisible && (
                        <RecordInternalRemakeModal mfgId={mfgId} labOrderId={order.id} />
                    )}
                    {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                    {order && (
                        <AddOrRemoveHoldButton
                            items={OrderItemV2Utils.parseItems(order.items_v2)}
                            onError={() => {}}
                            onSuccess={refetch}
                            order={order}
                            refetch={refetch}
                            salesOrderId={order.id}
                        />
                    )}
                    {switchToTatProgressView && (
                        <Tooltip title={<Text variant={'body1'}>Switch to TAT Progress view</Text>}>
                            <Button variant={'contained'} onClick={switchToTatProgressView} startIcon={'SyncAlt'}>
                                <span>TAT</span>
                            </Button>
                        </Tooltip>
                    )}
                </>
            }
        >
            {order ? (
                <Grid style={{ flexDirection: 'row' }}>
                    {additionalData?.getLabSalesOrderById && (
                        <OrderDetailTrackingServiceLevelSpeedBanner
                            salesOrderFragment={additionalData?.getLabSalesOrderById}
                        />
                    )}
                    <OrderDetailTrackingTitle order={order} />
                    <OrderDetailTrackerV2
                        orderTrackerEntries={additionalData?.getOrderTrackerEntries ?? []}
                        isOnHold={
                            order?.workflow_state.state === LabsGqlWorkflowStateEnum.OnHold && !order?.hold_in_review
                        }
                        padded
                        isPractice={false}
                        isLab
                    />
                    <OrderActionsGrid
                        items={OrderItemV2Utils.parseItems(order.items_v2)}
                        orderStatus={order.status}
                        salesOrderId={order.id}
                    />
                </Grid>
            ) : (
                <Grid container />
            )}
        </OrderDetailBlock>
    );
};
