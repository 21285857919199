import { LabInboxBulkActionIconButton } from '../../../../components/bulk-actions/LabInboxBulkActionIconButton';
import type { LabPortalOrderActionProps } from './LabPortalOrderActions.types';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlLabPortalNewNeedsManualEntryOrderListItemFragment } from '@orthly/graphql-operations';
import { Format } from '@orthly/runtime-utils';
import { useChangeSubmissionFn, makeLazyDialog, RootActionDialog, SimpleTextField } from '@orthly/ui';
import { FlossPalette, Button, CheckIcon } from '@orthly/ui-primitives';
import { useSnackbar } from 'notistack';
import React from 'react';

type manuallyEnteredArgs = {
    labOrderId: string;
    lmsOrderId: number | null;
    overrideValidations: boolean | null;
};

type manuallyEnteredResult = {
    success: boolean;
    labOrderId: string;
    message?: string | null;
};

const LabAcknowledgeManualEntryLmsOrder_Mutation = graphql(`
    mutation AcknowledgeManualEntryLmsOrder($args: [AcknowledgeManualEntryLmsOrderArgs!]!) {
        acknowledgeManualEntryLmsOrder(args: $args) {
            labOrderId
            success
            message
        }
    }
`);

export type BatchMessageResult = {
    errorIds: string[];
    successIds: string[];
    skippedOrders: { id: string; message: string | null }[];
};

function useBulkMarkOrderManuallyEnteredAction(refetchOrders: () => Promise<any>) {
    const { enqueueSnackbar } = useSnackbar();
    const [submitMtn] = useMutation(LabAcknowledgeManualEntryLmsOrder_Mutation);

    const runUpload = React.useCallback(
        async (args: manuallyEnteredArgs[]): Promise<BatchMessageResult> => {
            const result: BatchMessageResult = { errorIds: [], successIds: [], skippedOrders: [] };
            const orderIds = args.map(arg => arg.labOrderId);

            const { data, errors } = await submitMtn({ variables: { args } });
            const successfulOrderIds = data?.acknowledgeManualEntryLmsOrder
                ?.filter((resp: manuallyEnteredResult) => resp.success === true)
                .map((resp: manuallyEnteredResult) => resp.labOrderId);
            const skippedOrders = data?.acknowledgeManualEntryLmsOrder
                ?.filter((resp: manuallyEnteredResult) => resp.success === false)
                .map((resp: manuallyEnteredResult) => ({ id: resp.labOrderId, message: resp?.message || null }));
            if (errors) {
                result.errorIds.push(...orderIds);
            } else {
                result.successIds.push(...(successfulOrderIds ?? orderIds));
                result.skippedOrders.push(...(skippedOrders ?? []));
            }
            return result;
        },
        [submitMtn],
    );
    return useChangeSubmissionFn<BatchMessageResult, [manuallyEnteredArgs[]]>(runUpload, {
        onSuccess: async result => {
            const errorCount = result.errorIds.length;
            const successCount = result.successIds.length;
            const skippedCount = result.skippedOrders.length;
            const totalCount = errorCount + successCount + skippedCount;
            if (errorCount > 0) {
                const countLabel = totalCount > 1 ? `${errorCount} of ${totalCount} orders` : `order`;
                enqueueSnackbar(`Failed to mark ${countLabel} manually entered, please retry`, {
                    variant: 'error',
                    anchorOrigin: { horizontal: 'center', vertical: 'top' },
                });
            } else {
                const skippedMessage =
                    skippedCount > 0 ? ` Skipped ${Format.pluralize('invalid order', skippedCount)}` : '';
                enqueueSnackbar(
                    `Marked ${Format.pluralize('order', successCount)} as manually entered!${skippedMessage}`,
                    {
                        variant: 'success',
                        anchorOrigin: { horizontal: 'center', vertical: 'top' },
                    },
                );
            }
            await refetchOrders();
        },
    });
}

const useBulkMarkOrderEnteredDialog = makeLazyDialog<{
    submit: (labtracCaseNumber: number | null, attemptOverride: boolean) => Promise<string>;
    orderLabel: string;
    canInputCaseNumber: boolean;
}>(props => {
    const { submit, open, setOpen, orderLabel, canInputCaseNumber } = props;
    const [labtracCaseNumber, setLabtracCaseNumber] = React.useState<number | null>(null);
    const [disableSaveButton, setDisableSaveButton] = React.useState<boolean>(canInputCaseNumber);

    // errorMessage signifies that if non-null, a previous attempt was made and the next attempt will try to override
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

    return (
        <RootActionDialog
            title={`Are you sure you want to mark ${orderLabel} as manually entered?`}
            open={open}
            setOpen={setOpen}
            loading={false}
            buttonText={``}
            content={
                <div>
                    {canInputCaseNumber && (
                        <div style={{ marginBottom: '12px' }}>
                            {errorMessage && (
                                <div style={{ color: FlossPalette.REVIEW_RED }}>
                                    <div>{errorMessage}</div>
                                    <div>
                                        You may be able to override this validation. Are you sure this is the correct
                                        case number?
                                    </div>
                                </div>
                            )}
                            <SimpleTextField
                                value={labtracCaseNumber?.toString() ?? ''}
                                onChange={value => {
                                    const parsedCaseNumber = parseInt(value);
                                    if (parsedCaseNumber && parsedCaseNumber === parseFloat(value)) {
                                        setLabtracCaseNumber(parsedCaseNumber);
                                        setDisableSaveButton(false);
                                    } else {
                                        setDisableSaveButton(true);
                                    }
                                }}
                                label={'Labtrac case number (number only)'}
                            />
                        </div>
                    )}
                    <Button
                        variant={'primary'}
                        fullWidth={true}
                        onClick={async () => {
                            const resultErrorMessage = await submit(labtracCaseNumber, !!errorMessage);
                            if (resultErrorMessage && canInputCaseNumber) {
                                setErrorMessage(resultErrorMessage);
                            } else {
                                setOpen(false);
                            }
                        }}
                        disabled={canInputCaseNumber && disableSaveButton}
                    >
                        Accept Orders {!!errorMessage ? '(Attempt Override!)' : ''}
                    </Button>
                </div>
            }
        />
    );
});

export const LabPortalMarkManuallyEnteredAction: React.VFC<LabPortalOrderActionProps> = props => {
    const { selectedOrders, refetch, disabled } = props;
    const { submit, submitting } = useBulkMarkOrderManuallyEnteredAction(refetch);

    const canInputCaseNumber =
        selectedOrders?.length === 1 &&
        !(selectedOrders[0] as LabsGqlLabPortalNewNeedsManualEntryOrderListItemFragment)?.lmsOrderId;

    const submitOrders = React.useCallback(
        async (labtracCaseNumber: number | null, attemptOverride: boolean): Promise<string> => {
            const uselabtracCaseNumber = canInputCaseNumber && labtracCaseNumber ? Number(labtracCaseNumber) : null;
            const args = selectedOrders.map(o => ({
                labOrderId: o.id,
                lmsOrderId: uselabtracCaseNumber,
                overrideValidations: attemptOverride,
            }));
            const results = await submit(args);
            const skippedMessage = results.skippedOrders
                ? results.skippedOrders
                      .filter(o => !!o.message)
                      .map(o => o.message)
                      .join('. ')
                : '';
            return canInputCaseNumber ? skippedMessage : '';
        },
        [selectedOrders, submit, canInputCaseNumber],
    );

    const itemsLabel = props.onSingleRow ? '1 order' : Format.pluralize('order', selectedOrders.length);

    const [setBulkMarkOrderEnteredDialog, bulkMarkOrderEnteredDialog] = useBulkMarkOrderEnteredDialog({
        submit: submitOrders,
        orderLabel: itemsLabel,
        canInputCaseNumber: canInputCaseNumber,
    });
    return (
        <>
            <LabInboxBulkActionIconButton
                onClick={() => setBulkMarkOrderEnteredDialog(true)}
                loading={submitting}
                disabled={disabled ?? false}
                disableTooltip={disabled ?? false}
                selectionCount={selectedOrders.length}
                tooltipTitle={`Mark ${itemsLabel} as manually entered into lab tracking system`}
            >
                <CheckIcon
                    style={{ color: selectedOrders.length === 0 || submitting ? undefined : FlossPalette.STAR_GRASS }}
                />
            </LabInboxBulkActionIconButton>
            {bulkMarkOrderEnteredDialog}
        </>
    );
};
