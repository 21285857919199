import { PanLabelQRCode } from './PanLabelQRCode';
import { OrthlyBrowserConfig } from '@orthly/ui';
import { styled } from '@orthly/ui-primitives';
import React from 'react';

const LabelHeader = styled('div')({
    gridArea: 'header',
    display: 'grid',
    gridTemplateColumns: '40px repeat(2, 1fr) 0.65in',
    gridTemplateRows: '2.25lh 1.2lh',
    columnGap: '0.125in',
    gridTemplateAreas: `
    "labtrac pancodeshipby pancodeshipby labportal"
    "labtractext batchnumber ordernumber labportal"
    `,
    '& > .pan-label-labtrac-text-section': {
        gridArea: 'labtractext',
    },
    '& > .pan-label-batch-number-section': {
        gridArea: 'batchnumber',
        justifySelf: 'center',
    },
    '& > .pan-label-order-number-section': {
        gridArea: 'ordernumber',
        justifySelf: 'right',
    },
});

const TopCenterSubsection = styled('div')({
    justifySelf: 'center',
    lineHeight: '1',
    justifyContent: 'space-between',
    fontSize: '35px',
    fontFamily: 'Inter Semibold, Verdana, sans-serif',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, auto)',
    gridArea: 'pancodeshipby',
    width: '100%',
    margin: '0px 4px',
});

interface PanLabelHeaderProps {
    labOrderId: string;
    orderNumber: string;
    labtracId?: string;
    batchNumber?: string;
    panCode: string;
    shipByDate: Date;
}

export const PanLabelHeader: React.VFC<PanLabelHeaderProps> = ({
    labOrderId,
    orderNumber,
    labtracId,
    batchNumber,
    panCode,
    shipByDate,
}) => {
    const shipByString = `${shipByDate.getMonth() + 1}/${shipByDate.getDate()}`;

    // without using a third party package, handle the more extreme prefixes/suffixes such as 'PARTS', '##SP', etc. with a double-double digit date
    const headerTextLen = (panCode?.length || 1) + shipByString.length;
    const headerFontSize = headerTextLen <= 11 ? '32px' : '27px';

    // s=p is a short string for tracking because we may not be able to afford "utm_source" (if the URL is too long,
    // the QR code is too dense, and it becomes unreadable on the pan label).  We don't use orderNumber server-side.
    // It's a hack for something at the lab to get the order number by parsing the scanned QR code.
    const labPortalUrl = `${OrthlyBrowserConfig.labPortalUrl}/orders/${labOrderId}?s=p&n=${orderNumber}`;
    return (
        <LabelHeader>
            {labtracId && <PanLabelQRCode value={labtracId.replace(/[^A-Z0-9]/gi, '')} variant={'labtrac'} />}
            <TopCenterSubsection className={'pan-label-pan-code-section'} style={{ fontSize: headerFontSize }}>
                <div>{panCode}</div>
                <div>{shipByString}</div>
            </TopCenterSubsection>
            <PanLabelQRCode value={labPortalUrl} variant={'lab-portal'} />
            {labtracId && <div className={'pan-label-labtrac-text-section'}>{labtracId}</div>}
            {batchNumber && <div className={'pan-label-batch-number-section'}>{batchNumber}</div>}
            <div className={'pan-label-order-number-section'}>{orderNumber}</div>
        </LabelHeader>
    );
};
