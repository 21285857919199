import * as This from './ScanList.vm';
import _ from 'lodash';
import React from 'react';

export type Scan = {
    bookingAction: string;
    bookingTime: Date;
    orderId: string;
    stages: string[];
};

export function addScan(scans: Scan[], toAdd: Scan): Scan[] {
    const combined = [toAdd, ...scans];
    return _.uniqBy(combined, 'orderId');
}

export function clearScans(): Scan[] {
    return [];
}

export type ReduceScansAction = { type: 'ADD'; value: Scan } | { type: 'CLEAR' };

export function reduceScans(scans: Scan[], action: ReduceScansAction): Scan[] {
    if (action.type === 'ADD') {
        return This.addScan(scans, action.value);
    }
    if (action.type === 'CLEAR') {
        return This.clearScans();
    }
    throw new Error('Invalid action for reduceScans.');
}

export function useViewModel() {
    const [scans, dispatchScans] = React.useReducer(reduceScans, []);

    const addScan_ = (v: Scan) => dispatchScans({ type: 'ADD', value: v });
    const clearScans_ = () => dispatchScans({ type: 'CLEAR' });

    return { addScan: addScan_, clearScans: clearScans_, scans };
}
