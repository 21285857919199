import { QRCodeOrderSearch } from '../../../components/QcQrInput/QRCodeOrderSearch';
import { useViewModel } from './BookingForm.vm';
import { ScanList } from './components/ScanList';
import { SimpleSelect } from '@orthly/ui';
import {
    Box,
    Button,
    Checkbox,
    Chip,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    RadioGroup,
    Select,
    styled,
    Text,
} from '@orthly/ui-primitives';
import label from '@orthly/ui/assets/images/pan-code-label.png';
import React from 'react';

const StyledBookingForm = styled(Grid)({
    padding: '20px',
});

const StyledFormGroup = styled(Grid)({
    padding: '20px',
});

const LabelImage = styled('img')({
    paddingBottom: '50px',
    width: '500px',
});

const chipBoxSx = { display: 'flex', flexWrap: 'wrap', gap: 0.5 };
const stageGroupSx = { m: 1, width: 300 };

/** Submit a booking action for a case, department, and machine. */
export const BookingForm: React.VFC = () => {
    const {
        bookingAction,
        bookingActionOptions,
        clearScans,
        department,
        departmentOptions,
        disabled,
        isOvertime,
        machineId,
        machineIdOptions,
        setBookingAction,
        setDepartment,
        setIsOvertime,
        setMachineId,
        setStages,
        scans,
        stageOptions,
        stages,
        submit,
        userName,
    } = useViewModel();

    return (
        <StyledBookingForm container xs={12}>
            {userName && (
                <Grid xs={12}>
                    <Text>You are logged in as: {userName}</Text>
                </Grid>
            )}
            <StyledFormGroup xs={12}>
                <Text>Is this overtime work?</Text>
                <Checkbox checked={isOvertime} onChange={(_, v) => setIsOvertime(v)} />
            </StyledFormGroup>
            <StyledFormGroup xs={12}>
                <Text color={bookingAction === '' ? 'ATTENTION' : undefined}>Select book on / book off</Text>
                <RadioGroup handleChange={setBookingAction} options={bookingActionOptions} value={bookingAction} />
            </StyledFormGroup>
            {bookingAction !== '' && (
                <StyledFormGroup xs={12}>
                    <Text color={department === '' ? 'ATTENTION' : undefined}>Select your department</Text>
                    <SimpleSelect
                        label={'Department'}
                        onChange={setDepartment}
                        options={departmentOptions}
                        value={department}
                    />
                </StyledFormGroup>
            )}
            {department !== '' && (
                <>
                    {machineIdOptions.length > 0 && (
                        <StyledFormGroup xs={12}>
                            <Text>Select your machine ID</Text>
                            <SimpleSelect
                                label={'Machine ID'}
                                onChange={setMachineId}
                                options={machineIdOptions}
                                value={machineId}
                            />
                        </StyledFormGroup>
                    )}
                    {stageOptions.length > 0 && (
                        <StyledFormGroup xs={12}>
                            <Text color={stages?.length === 0 ? 'ATTENTION' : undefined}>Select your stages</Text>
                            <FormControl sx={stageGroupSx}>
                                <InputLabel>Select your stages</InputLabel>
                                <Select
                                    label={'Stage'}
                                    multiple
                                    onChange={setStages}
                                    renderValue={selected => (
                                        <Box sx={chipBoxSx}>
                                            {selected.map(value => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                    value={stages}
                                >
                                    {stageOptions.map(i => (
                                        <MenuItem key={i.value} value={i.value}>
                                            {i.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </StyledFormGroup>
                    )}
                    <StyledFormGroup xs={12}>
                        <Text color={'ATTENTION'}>Place your cursor in the input box below and scan the QR code</Text>
                        <LabelImage src={label} alt={'Pan Code Label'} />
                        <QRCodeOrderSearch disabled={disabled} label={'Scan QR code'} setActiveSearch={submit} />
                    </StyledFormGroup>
                </>
            )}
            <Grid container>
                <Grid item xs={4} />
                <Grid item xs={8}>
                    <Button disabled={scans.length === 0} onClick={clearScans} variant={'text'}>
                        Clear Scan Results
                    </Button>
                </Grid>
            </Grid>
            <Grid xs={12}>
                <ScanList scans={scans} />
            </Grid>
        </StyledBookingForm>
    );
};
