import { Grid } from '@orthly/ui-primitives';
import React from 'react';

export const LabtracDetails: React.FC<{ orderId: string }> = () => {
    // NOT YET IMPLEMENTED
    // TODO: Update this to display internal procedure data https://meetdandy.atlassian.net/browse/EPDSCM-1936
    return (
        <Grid
            sx={{
                padding: '24px',
                fontWeight: 'bold',
            }}
        >
            Sorry, we don't have any data to show you
        </Grid>
    );
};
