import { useQcStationAction } from './state/QcStation.actions';
import { useQcStationPropSelector, useQcStationSelector } from './state/QcStation.selectors';
import type { LabsGqlDoctorDeliveryAddressFragment, LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import {
    useCurrentManufacturerProfileQuery,
    useGetOrderByQcSearchQuery,
    useLabOrderQcStationFailMutation,
    useLabOrderQcStationPassMutation,
    useSingleMailingAddressQuery,
} from '@orthly/graphql-react';
import { useChangeSubmissionFn } from '@orthly/ui';
import { useFeatureFlag } from '@orthly/veneer';
import constate from 'constate';
import React from 'react';

const CADMUS_MANUFACTURER_ID = '1bdfee90-66d8-413e-8f46-fbfc8e5faab3';
const NEW_PERFECT_MANUFACTURER_ID = 'aa951eba-8a82-4c7b-866c-a7acbd6376ee';
const overseasFulfillmentLabIds = new Set([CADMUS_MANUFACTURER_ID, NEW_PERFECT_MANUFACTURER_ID]);

function useQcIssuesArray() {
    const rawIssues = useQcStationSelector(s => s.issues);
    return React.useMemo(() => Object.entries(rawIssues).map(([id, { options }]) => ({ id, options })), [rawIssues]);
}

interface QcFailAction {
    submitQcFailed(options: { checkedItems: Record<string, boolean> }): Promise<unknown>;

    qcFailSubmitting: boolean;
}

function useQcStationFailMutation(orderId?: string): QcFailAction {
    const onFailed = useQcStationAction('SET_ORDER_FAILED');
    const issues = useQcIssuesArray();
    const failReason = useQcStationSelector(s => s.failReason);
    const [rawFail] = useLabOrderQcStationFailMutation();
    const { submit: submitQcFailed, submitting: qcFailSubmitting } = useChangeSubmissionFn(rawFail, {
        successMessage: () => [`Fail recorded!`, {}],
        onSuccess: () => onFailed(),
    });
    return {
        qcFailSubmitting,
        submitQcFailed({ checkedItems }: { checkedItems: Record<string, boolean> }) {
            return submitQcFailed({
                variables: {
                    issues,
                    completed_checklist_item_ids: Object.entries(checkedItems).flatMap(([itemId, completed]) =>
                        completed ? [itemId] : [],
                    ),
                    order_id: orderId ?? ``,
                    reason: failReason,
                },
            });
        },
    };
}

interface QcPassAction {
    submitQcPassed(options: { checkedItems: Record<string, boolean> }): Promise<void>;

    qcPassSubmitting: boolean;
    qcPassError: string | null;
    mailingAddress?: LabsGqlDoctorDeliveryAddressFragment;
}

function useQcStationPassMutation(order?: LabsGqlLabOrderFragment): QcPassAction {
    const onPassed = useQcStationAction('SET_ORDER_PASSED');
    const issues = useQcIssuesArray();
    const [rawPass] = useLabOrderQcStationPassMutation();
    const { value: disableQcStationShipPopupForLabIds } = useFeatureFlag('disableQcStationShipPopupForLabIds');
    const { value: enableOverseasQcStation } = useFeatureFlag('enableOverseasQcStation');
    const { submit: submitPass, submitting: qcPassSubmitting } = useChangeSubmissionFn(rawPass, {
        successMessage: () => [`Pass recorded!`, {}],
        onSuccess: () => onPassed({}),
        onError: ({ message: error }) => onPassed({ error }),
    });
    const setShippingDialogOpen = useQcStationAction('TOGGLE_SHIPPING_OPEN');
    const { data: manufacturerProfileData, error: errManufacturerProfileFetch } = useCurrentManufacturerProfileQuery({
        fetchPolicy: 'cache-first',
    });
    const enablePortalShip = !!manufacturerProfileData?.profile.feature_states.portalShip;
    // EPDSCM-1711: We want to hide the shipping dialog for Cadmus and New Perfect orders when:
    //   1. enableOverseaQcStation is enabled;
    //   2. the logged-in user is a Cadmus or New Perfect user (as opposed to a Lehi user, who *should* still be able
    //      to ship from the QC Station for these orders for now); and
    //   3. the order is a Cadmus or New Perfect order (which should always be the case for Cadmus/New Perfect users)
    // Eventually, we can just hide the dialog for all Cadmus/New Perfect orders, but not until we process all these
    // orders on the Batch Shipping screen in Lehi instead of on the QC Station screen.
    const hideForOverseasLab =
        !!enableOverseasQcStation &&
        manufacturerProfileData?.profile.id === order?.manufacturer_id &&
        overseasFulfillmentLabIds.has(order?.manufacturer_id ?? '');
    // we also want to hide the shipping dialog for certain labs
    // if the ff is not defined, we will default to showing the dialog
    // if it is defined, and contains the order's manufacturer_id, we will hide the dialog
    const hidePortalShip =
        (!!disableQcStationShipPopupForLabIds &&
            !!order?.manufacturer_id &&
            disableQcStationShipPopupForLabIds.includes(order.manufacturer_id)) ||
        hideForOverseasLab;

    const mailingAddressId = order?.mailing_address_id;
    const { data: addressData, error: errMailingAddressFetch } = useSingleMailingAddressQuery({
        variables: { id: mailingAddressId ?? '' },
        skip: !mailingAddressId,
    });

    const qcPassError: string | null =
        errManufacturerProfileFetch?.message ||
        errMailingAddressFetch?.message ||
        (manufacturerProfileData ? null : `Failed to load manufacturer profile.`) ||
        (addressData ? null : `Failed to load order mailing address.`);

    const submitQcPassed = React.useCallback(
        async ({ checkedItems }: { checkedItems: Record<string, boolean> }) => {
            await submitPass({
                variables: {
                    issues,
                    completed_checklist_item_ids: Object.entries(checkedItems).flatMap(([itemId, completed]) =>
                        completed ? [itemId] : [],
                    ),
                    order_id: order?.id ?? '',
                    employee_id: null,
                    integration_order_id: null,
                },
            });
            if (enablePortalShip && !hidePortalShip) {
                setShippingDialogOpen(true);
            }
        },
        [enablePortalShip, issues, order?.id, setShippingDialogOpen, submitPass, hidePortalShip],
    );

    return { submitQcPassed, qcPassError, qcPassSubmitting, mailingAddress: addressData?.singleAddress };
}

interface QcStationRemoteData extends QcFailAction, QcPassAction {
    labOrderFragment?: LabsGqlLabOrderFragment;
    labOrderLoading: boolean;
}

function useQcStationRemoteDataRoot(): QcStationRemoteData {
    const { activeOrderIdSearch: orderId } = useQcStationPropSelector(['activeOrderIdSearch']);

    const { data: qcSearchData, loading: labOrderLoading } = useGetOrderByQcSearchQuery({
        variables: { search_term: orderId ?? '' },
        skip: !orderId,
        fetchPolicy: 'cache-and-network',
    });
    const labOrderFragment = qcSearchData?.getOrderByQcSearch ?? undefined;
    const failAction = useQcStationFailMutation(labOrderFragment?.id);
    const passAction = useQcStationPassMutation(labOrderFragment);
    return {
        ...failAction,
        ...passAction,
        labOrderFragment,
        labOrderLoading,
    };
}

const [QcStationDataProviderGen, useQcStationDataGen] = constate(useQcStationRemoteDataRoot);
export const QcStationDataProvider = QcStationDataProviderGen;

export const useQcStationRemoteData: () => QcStationRemoteData = useQcStationDataGen;
