import { getShelfNumberForAddress } from '../batch-shipping.utils';
import { useQuery } from '@apollo/client';
import type { BatchShipDestinationAddressFragment } from '@orthly/graphql-inline-react';
import { graphql, getFragmentData } from '@orthly/graphql-inline-react';
import { useSession } from '@orthly/session-client';

const BatchShipDestinationAddress_Fragment = graphql(`
    fragment BatchShipDestinationAddress on PartnerDeliveryAddress {
        id
        idNumber
        street_one
        street_two
        city
        state
        postal_code
    }
`);

const BatchShipDestination_Query = graphql(`
    query BatchShipDestination($id: String!) {
        singleAddress(id: $id) {
            ...BatchShipDestinationAddress
        }
    }
`);

export type BatchShippingDestinationAddress = BatchShipDestinationAddressFragment & {
    // the shelf assignment for orders going to this address
    shelfNumber: string | null;
};

type UseBatchShipDestinationAddressResult = {
    loading: boolean;
    mailingAddress: BatchShippingDestinationAddress | null;
};

/**
 * Hook that loads the mailing address and determines the shelf assignment for orders going to this address, if applicable
 *
 * @param {string} mailingAddressId - ID of the orders delivery address
 * @param {string} practiceId - ID of the practice that placed the order
 * @returns {UseBatchShipDestinationAddressResult}
 */
export function useBatchShipDestinationAddress(mailingAddressId: string): UseBatchShipDestinationAddressResult {
    const orgId = useSession()?.organization_id ?? null;
    const { data, loading } = useQuery(BatchShipDestination_Query, {
        variables: { id: mailingAddressId },
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-first',
    });
    const mailingAddress = getFragmentData(BatchShipDestinationAddress_Fragment, data?.singleAddress) ?? null;
    const shelfNumber = getShelfNumberForAddress(mailingAddress?.idNumber ?? null, orgId);
    return { loading, mailingAddress: mailingAddress ? { ...mailingAddress, shelfNumber } : null };
}
