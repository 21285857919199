import { BookingForm } from '../components/BookingForm/BookingForm';
import { Grid } from '@orthly/ui-primitives';

/** The content for the booking screen. */
export const BookingRoot: React.VFC = () => {
    return (
        <Grid container>
            <BookingForm />
        </Grid>
    );
};
