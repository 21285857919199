import { type Scan } from './ScanList.vm';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

type Props = {
    scans: Scan[];
};

/** Display a list of scans. */
export const ScanList: React.VFC<Props> = ({ scans }) => {
    return (
        <Grid container>
            <Grid item xs={4} />
            <Grid item xs={8}>
                {scans.length} Scanned Cases
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={8}>
                <ul>
                    {scans.map(i => (
                        <Grid key={i.orderId}>
                            <li>
                                {i.bookingTime.toLocaleString()} - {i.bookingAction} -{' '}
                                <a href={`/lab/${i.orderId}`}>{i.orderId.split('-')[0]}</a>
                                {i.stages.length > 0 && (
                                    <ul>
                                        {i.stages.map(stage => (
                                            <li key={stage}>{stage}</li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        </Grid>
                    ))}
                </ul>
            </Grid>
        </Grid>
    );
};
