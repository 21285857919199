import { useSelectOrders } from '../../../utils/CheckboxSelectUtils';
import { calculateShipDateDisplay, calculateTimeInListDisplay } from '../../../utils/DisplayUtils';
import { useLabPortalOrderListViewContext } from '../../providers/LabPortalOrderListViewProvider';
import { useLabPortalOrderTabCountsContext } from '../../providers/LabPortalOrderTabCountsProvider';
import type { IndicatorFlagKey } from '../shared/IndicatorFlags';
import { IndicatorFlags } from '../shared/IndicatorFlags';
import type { ColumnDef } from '../shared/LabPortalOrderListView';
import {
    LabPortalOrderListView,
    LabPortalListViewNameColumn,
    LabPortalListViewBulkActionsHeader,
} from '../shared/LabPortalOrderListView';
import { NewOrderActionsDisplay, OrderItemsDisplay } from '../shared/ListViewDisplays';
import type { LabsGqlLabPortalNewOrderListItemFragment } from '@orthly/graphql-operations';
import { useLabPortalNewOrdersWithoutDesignByListViewQuery } from '@orthly/graphql-react';
import { LoadBlocker } from '@orthly/ui';
import { Grid, CheckboxPrimitive as Checkbox } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

export const LabPortalNewWithoutDesignListView: React.VFC = () => {
    const { filters, sort } = useLabPortalOrderListViewContext();
    const { refetch: refetchCounts } = useLabPortalOrderTabCountsContext();

    const { data, loading, refetch } = useLabPortalNewOrdersWithoutDesignByListViewQuery({
        fetchPolicy: 'no-cache',
        variables: { criteria: filters, labPortalSort: sort },
    });

    const { value: isRushShippingColumnEnabled } = useFeatureFlag('rushEnableLabPortalUi');

    const { selectedOrderIds, selectedOrders, onSelectOrder, onSelectAll, resetSelected } = useSelectOrders(
        data?.orders ?? [],
    );

    const columns: ColumnDef[] = React.useMemo(
        () =>
            _.compact([
                {
                    label: (
                        <Checkbox
                            color={'secondary'}
                            checked={
                                data?.orders?.length !== undefined &&
                                data?.orders?.length > 0 &&
                                selectedOrderIds.length === data?.orders?.length
                            }
                            onChange={onSelectAll}
                        />
                    ),
                    cell: order => (
                        <Checkbox
                            color={'secondary'}
                            checked={selectedOrderIds.includes(order.id)}
                            onChange={onSelectOrder(order.id)}
                        />
                    ),
                },
                {
                    label: 'Name',
                    cell: order => (
                        <LabPortalListViewNameColumn
                            orderId={order.id}
                            orderNumber={order.orderNumber}
                            patientName={order.patientName}
                        />
                    ),
                },
                {
                    label: 'Items',
                    cell: (order: LabsGqlLabPortalNewOrderListItemFragment) => (
                        <OrderItemsDisplay items={order.items} />
                    ),
                },
                {
                    label: 'Model Type',
                    cell: order => order.modelType ?? '',
                },
                {
                    label: 'Flags',
                    cell: order =>
                        order.listFlags?.map((flag: IndicatorFlagKey) => (
                            <Grid key={flag}>{IndicatorFlags[flag]}</Grid>
                        )),
                },
                {
                    label: 'In List Date',
                    cell: order => calculateTimeInListDisplay(order.inListDate),
                },
                isRushShippingColumnEnabled
                    ? {
                          label: 'Rush Shipping',
                          cell: order =>
                              order.priority === 'HIGH' ? (
                                  <Grid
                                      style={{
                                          color: '#8F184B',
                                          fontSize: '12px',
                                          fontWeight: 600,
                                      }}
                                  >
                                      {'Rush Ship'}
                                  </Grid>
                              ) : (
                                  ''
                              ),
                      }
                    : undefined,
                {
                    label: 'Ship Date',
                    cell: order => calculateShipDateDisplay(order.shipDate),
                },
                {
                    label: (
                        <LabPortalListViewBulkActionsHeader
                            bulkActions={
                                <NewOrderActionsDisplay
                                    orders={selectedOrders}
                                    refetch={async () => {
                                        resetSelected();
                                        await refetch();
                                        await refetchCounts();
                                    }}
                                />
                            }
                        />
                    ),
                    cell: order => (
                        <NewOrderActionsDisplay
                            orders={[order]}
                            refetch={async () => {
                                resetSelected();
                                await refetch();
                                await refetchCounts();
                            }}
                        />
                    ),
                },
            ]),
        [
            data?.orders?.length,
            onSelectAll,
            onSelectOrder,
            refetch,
            refetchCounts,
            resetSelected,
            selectedOrderIds,
            selectedOrders,
            isRushShippingColumnEnabled,
        ],
    );

    return (
        <LoadBlocker blocking={loading}>
            <LabPortalOrderListView orders={data?.orders ?? []} columns={columns} />
        </LoadBlocker>
    );
};
