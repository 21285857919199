import { useCalculateContentHeight } from '../../../utils/LayoutUtils';
import type { LabOrderForLabPortal } from '../LabsUtils';
import { useLabPortalOrderDetails } from '../LabsUtils';
import { OrderDetailBody } from './OrderDetailBody';
import { OrderDetailChat } from './OrderDetailChat';
import { OrderDetailTimelineItemsProvider } from './OrderDetailTimelineItemsProvider';
import { OrderDetailTopBar } from './OrderDetailTopBar.graphql';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { useListChatMessagesQuery } from '@orthly/graphql-react';
import { UuidUtils } from '@orthly/runtime-utils';
import { stylesFactory, Grid } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import moment from 'moment-timezone';
import React from 'react';
import { useParams } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LabPortalOrderDetails_Fragment = graphql(`
    fragment LabPortalOrderDetails_Fragment on LabPortalOrderDetails {
        stack_code
    }
`);

export const LabPortalOrderDetails_Query = graphql(`
    query LabPortalOrderDetails_Query($orderId: String!) {
        order: labPortalOrderDetails(orderId: $orderId) {
            ...LabPortalOrderDetails_Fragment
        }
    }
`);

const useStyles = stylesFactory(() => ({
    mainContainer: {
        overflow: 'auto',
        flexWrap: 'nowrap',
    },
}));

type InnerOrderDetailRootProps = {
    disableBackButton?: boolean;
    loading: boolean;
    order?: LabOrderForLabPortal;
    refetch: (variables?: any) => Promise<any>;
    salesOrder: {
        createdAt: Date;
        id: string;
    };
};

export const InnerOrderDetailRoot: React.FC<InnerOrderDetailRootProps> = props => {
    const { order, salesOrder, refetch, loading, disableBackButton } = props;
    const classes = useStyles();
    const { value: maintenanceBannerPractice } = useFeatureFlag('maintenanceBannerPractice');

    const { data: chatMessagesData, refetch: refetchChatMessages } = useListChatMessagesQuery({
        variables: { entity_ids: [order?.id ?? ''] },
        skip: !UuidUtils.isUUID(order?.id),
    });
    const chatMessages = React.useMemo(() => {
        return chatMessagesData?.listChatMessages ?? [];
    }, [chatMessagesData]);

    const contentHeight = useCalculateContentHeight({
        maintenanceBannerPractice: !!maintenanceBannerPractice?.length,
    });

    return (
        <Grid container style={{ background: '#fff' }}>
            <Grid
                container
                item
                xs={9}
                direction={'column'}
                className={classes.mainContainer}
                style={{ maxHeight: contentHeight }}
            >
                <OrderDetailTopBar order={order} salesOrder={salesOrder} disableBackButton={disableBackButton} />
                <OrderDetailBody order={order} loading={loading} refetch={refetch} />
            </Grid>
            <Grid container item xs={12} md={3} direction={'column'}>
                <OrderDetailChat
                    order={order}
                    refetchOrder={refetch}
                    loading={loading}
                    chatMessages={chatMessages}
                    refetchMessages={refetchChatMessages}
                />
            </Grid>
        </Grid>
    );
};

const OrderDetailRootQuery = graphql(`
    query LabPortalOrderDetailRootLabSalesOrder_Query($id: String!) {
        getLabSalesOrderById(id: $id) {
            id
            created_at
        }
    }
`);

export const OrderDetailRoot: React.FC = () => {
    const { orderId } = useParams<{ orderId: string }>();

    const { order, refetch: refetchOrder, loading } = useLabPortalOrderDetails(orderId, { fetchPolicy: 'no-cache' });
    const { data: salesOrderData, refetch: refetchSalesOrder } = useQuery(OrderDetailRootQuery, {
        variables: { id: orderId },
    });
    const refetch = async (variables: any) => {
        await refetchOrder(variables);
        await refetchSalesOrder(variables);
    };

    const salesOrderResult = salesOrderData?.getLabSalesOrderById;
    if (!salesOrderResult) {
        return null;
    }

    const salesOrder = {
        id: salesOrderResult.id,
        createdAt: moment(salesOrderResult.created_at).toDate(),
    };
    return (
        <OrderDetailTimelineItemsProvider order={order} orderId={orderId}>
            <InnerOrderDetailRoot order={order} salesOrder={salesOrder} refetch={refetch} loading={loading} />
        </OrderDetailTimelineItemsProvider>
    );
};
