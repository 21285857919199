import { useSelectOrders } from '../../../utils/CheckboxSelectUtils';
import { calculateShipDateDisplay, calculateTimeInListDisplay } from '../../../utils/DisplayUtils';
import { LabPortalSkipAutoNestingAction } from '../../LabPortalOrderActions/LabPortalSkipAutoNestAction.graphql';
import { useLabPortalOrderListViewContext } from '../../providers/LabPortalOrderListViewProvider';
import { useLabPortalOrderTabCountsContext } from '../../providers/LabPortalOrderTabCountsProvider';
import type { IndicatorFlagKey } from '../shared/IndicatorFlags';
import { IndicatorFlags } from '../shared/IndicatorFlags';
import type { ColumnDef } from '../shared/LabPortalOrderListView';
import {
    LabPortalOrderListView,
    LabPortalListViewNameColumn,
    LabPortalListViewBulkActionsHeader,
} from '../shared/LabPortalOrderListView';
import { OrderItemsDisplay } from '../shared/ListViewDisplays';
import type { LabsGqlLabPortalNestingInProgressOrderListItemFragment } from '@orthly/graphql-operations';
import { useLabPortalAutoNestingInProgressOrdersByListViewQuery } from '@orthly/graphql-react';
import { LoadBlocker } from '@orthly/ui';
import { Grid, CheckboxPrimitive as Checkbox } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import _ from 'lodash';
import moment from 'moment/moment';
import React from 'react';

export const LabPortalIntakeAutoNestingListView: React.VFC = () => {
    const { filters, sort } = useLabPortalOrderListViewContext();
    const { refetch: refetchCounts } = useLabPortalOrderTabCountsContext();

    const { value: isRushShippingColumnEnabled } = useFeatureFlag('rushEnableLabPortalUi');

    const { data, loading, refetch } = useLabPortalAutoNestingInProgressOrdersByListViewQuery({
        fetchPolicy: 'no-cache',
        variables: { criteria: filters, labPortalSort: sort },
    });

    const { selectedOrderIds, selectedOrders, onSelectOrder, onSelectAll, resetSelected } = useSelectOrders(
        data?.orders ?? [],
    );

    const columns: ColumnDef[] = React.useMemo(
        () =>
            _.compact([
                {
                    label: (
                        <Checkbox
                            color={'secondary'}
                            checked={
                                data?.orders?.length !== undefined &&
                                data?.orders?.length > 0 &&
                                selectedOrderIds.length === data?.orders?.length
                            }
                            onChange={onSelectAll}
                        />
                    ),
                    cell: order => (
                        <Checkbox
                            color={'secondary'}
                            checked={selectedOrderIds.includes(order.id)}
                            onChange={onSelectOrder(order.id)}
                        />
                    ),
                },
                {
                    label: 'Name',
                    cell: order => (
                        <LabPortalListViewNameColumn
                            orderId={order.id}
                            orderNumber={order.orderNumber}
                            patientName={order.patientName}
                        />
                    ),
                },
                {
                    label: 'Items',
                    cell: (order: LabsGqlLabPortalNestingInProgressOrderListItemFragment) => (
                        <OrderItemsDisplay items={order.items} />
                    ),
                },
                {
                    label: 'Model Type',
                    cell: order => order.modelType ?? '',
                },
                {
                    label: 'Flags',
                    cell: order =>
                        order.listFlags?.map((flag: IndicatorFlagKey) => (
                            <Grid key={flag}>{IndicatorFlags[flag]}</Grid>
                        )),
                },
                {
                    label: 'In List Date',
                    cell: order => calculateTimeInListDisplay(order.inListDate),
                },
                isRushShippingColumnEnabled
                    ? {
                          label: 'Rush Shipping',
                          cell: order =>
                              order.priority === 'HIGH' ? (
                                  <Grid
                                      style={{
                                          color: '#8F184B',
                                          fontSize: '12px',
                                          fontWeight: 600,
                                      }}
                                  >
                                      {'Rush Ship'}
                                  </Grid>
                              ) : (
                                  ''
                              ),
                      }
                    : undefined,
                {
                    label: 'Ship Date',
                    cell: order => calculateShipDateDisplay(order.shipDate),
                },
                {
                    label: 'Stack Code',
                    cell: order => order.stackCodeLabel ?? '',
                },
                {
                    label: (
                        <LabPortalListViewBulkActionsHeader
                            bulkActions={
                                <LabPortalSkipAutoNestingAction
                                    selectedOrders={selectedOrders}
                                    onSingleRow={selectedOrders.length === 1}
                                    refetch={async () => {
                                        resetSelected();
                                        await refetch();
                                        await refetchCounts();
                                    }}
                                />
                            }
                        />
                    ),
                    cell: order => (
                        <LabPortalSkipAutoNestingAction
                            selectedOrders={[order]}
                            onSingleRow={true}
                            refetch={async () => {
                                resetSelected();
                                await refetch();
                                await refetchCounts();
                            }}
                        />
                    ),
                },
            ]),
        [
            data?.orders?.length,
            onSelectAll,
            onSelectOrder,
            refetch,
            refetchCounts,
            resetSelected,
            selectedOrderIds,
            selectedOrders,
            isRushShippingColumnEnabled,
        ],
    );

    const ordersWithAlerts = React.useMemo(() => {
        return (data?.orders ?? []).map(o => {
            const diffHours = moment().diff(moment(o.inListDate), 'hours');
            if (diffHours >= 5 /* Alert if order has been sitting in list for longer than 5 hours */) {
                return {
                    ...o,
                    alert: true,
                };
            }
            return o;
        });
    }, [data?.orders]);

    return (
        <LoadBlocker blocking={loading}>
            <LabPortalOrderListView orders={ordersWithAlerts} columns={columns} />
        </LoadBlocker>
    );
};
