import { useStableCallback } from './useStableCallback';
import type { MutationTuple } from '@apollo/client';
// eslint-disable-next-line no-restricted-imports
import type { GraphQLError } from 'graphql';
import _ from 'lodash';
import type { OptionsObject } from 'notistack';
import { useSnackbar } from 'notistack';
import React from 'react';

export interface ApolloError extends Error {
    message: string;
    graphQLErrors: ReadonlyArray<GraphQLError>;
    networkError: Error | null;
    extraInfo: any;
}

export type ChangeSubmissionFn<R, Args extends any[] = []> = (...args: Args) => Promise<R>;

interface CustomOptionsObject extends OptionsObject {
    submessage?: string;
}

// If message undefined, the snackbar wont show
type SnackbarResultHandler<R> = (result: R) => [string | undefined, (CustomOptionsObject | undefined)?];

// EPDPLT-3738 Unused variables should be removed.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface UseChangeSubmissionFnConfig<Result, Args extends any[] = []> {
    closeOnComplete?: boolean;
    closeOnError?: boolean;
    onSuccess?: (result: Result) => void;
    onError?: (error: Error) => void;
    successMessage?: SnackbarResultHandler<Result>;
    errorMessage?: SnackbarResultHandler<Error>;
    defaultOpen?: boolean;
    disableDefaultErrorMessage?: boolean; // only disabled in legacy uses
}

function getErrorMessage(err: Error): string {
    return _.get(err, '.graphQLErrors[0].message', err.message);
}

export function useRootActionCommand<TCommand, TResult>(
    [submitMutation]: MutationTuple<TResult, TCommand>,
    {
        successMessage,
        onSuccess,
    }: {
        successMessage?: string | SnackbarResultHandler<{ data: TResult }>;
        onSuccess?: (result: { data: TResult }) => void;
    },
) {
    const mtnSubmitter = (variables: TCommand) => submitMutation({ variables });
    return useChangeSubmissionFn<any, [TCommand]>(mtnSubmitter, {
        onSuccess,
        successMessage: typeof successMessage === 'string' ? () => [successMessage, {}] : successMessage,
        closeOnComplete: true,
        errorMessage: e => [getErrorMessage(e), {}],
    });
}

export function isApolloError(e: any): e is ApolloError {
    return typeof e === 'object' && !!e.graphQLErrors;
}

export function apolloErrorMessage(e: Error, messagePrefix: string = '') {
    // TODO: [EPDPLT-4244] Better apollo error handling
    if (isApolloError(e) && e.graphQLErrors[0]) {
        const apolloMessage =
            typeof e.graphQLErrors[0].message === 'object' &&
            typeof (e.graphQLErrors[0].message as any).message === 'string'
                ? (e.graphQLErrors[0].message as any).message
                : (e.graphQLErrors[0].message as any).error;
        return `${messagePrefix}${apolloMessage || e.message}`;
    }
    return `${messagePrefix}${e.message}`;
}

/**
 * Utility function for managing 'action' components
 * Config functions call order (if applicable):
 * - successMessage / errorMessage handlers
 * - setSubmitting(false)
 * - onSuccess / onError handlers
 * - closeOnComplete / closeOnError -> setOpen()
 */
export function useChangeSubmissionFn<Result, Args extends any[] = []>(
    callable: ChangeSubmissionFn<Result, Args>,
    config: UseChangeSubmissionFnConfig<Result, Args>,
) {
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpenInternal] = React.useState<boolean>(config.defaultOpen || false);
    const [promise, setPromise] = React.useState<Promise<Result> | undefined>();
    const submitting = !!promise;
    const [called, setCalled] = React.useState<boolean>(false);
    const setOpen = (open: boolean) => {
        if (!submitting) {
            setOpenInternal(open);
        }
    };

    function unstable_submit(...args: Args): Promise<Result> {
        if (promise) {
            return promise;
        }
        setCalled(true);
        const newPromise = (async () => {
            // wait a moment to give a chance to set the new promise below.
            // this avoids a race condition between setting promise to new promise vs setting it to undefined.
            await Promise.resolve();
            try {
                const result = await callable(...args);
                if (config.successMessage) {
                    const [message, options] = config.successMessage(result);
                    !!message && enqueueSnackbar(message, { variant: 'success', ...options });
                }
                // use try to avoid being stuck submitting if the success handler throws.
                try {
                    config.onSuccess?.(result);
                } finally {
                    setPromise(undefined);
                    config.closeOnComplete && setOpen(false);
                }
                // let the submission promise resolve with the result.
                return result;
            } catch (e: any) {
                if (config.errorMessage) {
                    const [message, options] = config.errorMessage(e);
                    !!message && enqueueSnackbar(message, { variant: 'error', ...options });
                } else if (!config.disableDefaultErrorMessage) {
                    enqueueSnackbar(apolloErrorMessage(e), { variant: 'error' });
                }
                // use try to avoid being stuck submitting if the error handler throws.
                try {
                    config.onError?.(e);
                } finally {
                    setPromise(undefined);
                    config.closeOnError && setOpen(false);
                }
                // make the submission promise reject with an error.
                throw e;
            }
        })();
        setPromise(newPromise);
        return newPromise;
    }

    const submit = useStableCallback(unstable_submit);

    return { submitting, submit, called, open, setOpen };
}
