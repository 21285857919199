import { PartnerOrdersSpotlightSearch } from '../../components/PartnerOrdersSpotlightSearch';
import { StackCodeSpotlightSearch } from '../../components/StackCodeSpotlightSearch.graphql';
import { usePracticeAppSelector } from '../../redux';
import { usePracticeLogout } from '../../redux/auth';
import { usePartnerUiAction } from '../../redux/ui.actions';
import { useManufacturerIdFromSession } from '../../utils/authorization';
import { QRCodeScannerSpotlight } from './components/QRCodeScannerSpotlight';
import { useManufacturerAction } from './state/manufacturer.actions.graphql';
import type { ManufacturerScreen, ManufacturerOrderScreenWithCount } from './state/manufacturer.reducer';
import { useManufacturerSelector } from './state/manufacturer.selectors';
import { useManufacturerOptions } from './state/useManufacturerOptions';
import { useLabPortalSidebarCountsQuery } from '@orthly/graphql-react';
import type { NavigationEntryBottom, NavigationEntry } from '@orthly/ui';
import { Navigation } from '@orthly/ui';
import { useScreenIsMobile } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';
import { useHistory, Link } from 'react-router-dom';

const NavigationLink =
    (screen: string): React.FC =>
    props => (
        <Link style={{ textDecoration: 'none', color: 'inherit' }} to={`/${screen}`}>
            {props.children}
        </Link>
    );

function useBottomItems(enabledPages: Set<string>) {
    const logout = usePracticeLogout();
    const setShowingSearchStackCode = usePartnerUiAction('SET_STACKCODE_SEARCH');
    const showingSearchStackCode = usePracticeAppSelector(s => s.ui.activeSpotlight === 'searchStackCode');

    const setShowingSearch = usePartnerUiAction('SET_SPOTLIGHT_OPEN');
    const showingSearch = usePracticeAppSelector(s => s.ui.activeSpotlight === 'search');

    const setQRCodeOpen = usePartnerUiAction('SET_QR_CODE_OPEN');
    const activeScreen = useManufacturerSelector(s => s.screen.screenSelection);
    const setScreen = useManufacturerAction('SET_SCREEN');
    const history = useHistory();
    const openShopify = () => {
        window.open('https://dandy-materials.myshopify.com/');
    };
    const mfgId = useManufacturerIdFromSession();
    const { value: enableBatchShipmentsForLabIds = [] } = useFeatureFlag('enableBatchShippingForLabIds');

    return React.useMemo(() => {
        const setScreenIfNotActive = (s: ManufacturerScreen) => {
            if (activeScreen !== s) {
                history.push(`/${s}`);
                setScreen({ screen: s });
            }
        };
        const bottomItems: NavigationEntryBottom[] = [];
        if (enabledPages.has('Search')) {
            bottomItems.push({
                active: false,
                title: 'Search',
                iconName: 'SearchIcon',
                onClick: () => {
                    if (!showingSearch) {
                        setShowingSearch(true);
                    }
                },
                isFirstSectionItem: true,
            });
        }
        if (enabledPages.has('searchStackCode')) {
            bottomItems.push({
                active: false,
                title: 'Stack Code',
                iconName: 'SearchIcon',
                onClick: () => {
                    if (!showingSearchStackCode) {
                        setShowingSearchStackCode(true);
                    }
                },
            });
        }
        if (enabledPages.has('QCStation')) {
            bottomItems.push({
                active: false,
                title: 'QC Station',
                iconName: 'AssignmentTurnedIn',
                onClick: () => window.open('/qc-station', '_blank'),
            });
        }
        if (enabledPages.has('QRCodeScanner')) {
            bottomItems.push({
                active: false,
                title: 'Scan QR Code',
                iconName: 'CropFree',
                onClick: () => setQRCodeOpen(true),
            });
        }
        if (enabledPages.has('Billing')) {
            bottomItems.push({
                active: activeScreen === 'Billing',
                title: 'Billing',
                iconName: 'MonetizationOn',
                onClick: () => setScreenIfNotActive('Billing'),
            });
        }
        if (enabledPages.has('MetalPricing')) {
            bottomItems.push({
                active: false,
                title: 'Metal Pricing',
                iconName: 'CubeIcon',
                onClick: () => setScreenIfNotActive('MetalPricing'),
                Container: NavigationLink('metal-pricing'),
            });
        }
        if (enabledPages.has('LabSupplies')) {
            bottomItems.push({
                active: false,
                onClick: () => {
                    openShopify();
                },
                title: 'Lab Supplies',
                iconName: 'CartIcon',
            });
        }
        if (mfgId && enableBatchShipmentsForLabIds.includes(mfgId)) {
            bottomItems.push({
                active: activeScreen === 'BatchShipments',
                title: 'Batch Shipping',
                iconName: 'LocalShipping',
                onClick: () => setScreenIfNotActive('BatchShipments'),
                Container: NavigationLink('batch-shipments'),
            });
        }
        bottomItems.push({
            active: false,
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick: async () => {
                await logout();
            },
            title: 'Logout',
            iconName: 'ExitToApp',
        });
        return bottomItems;
    }, [
        enabledPages,
        enableBatchShipmentsForLabIds,
        mfgId,
        activeScreen,
        history,
        setScreen,
        showingSearch,
        setShowingSearch,
        setQRCodeOpen,
        setShowingSearchStackCode,
        showingSearchStackCode,
        logout,
    ]);
}

const useManufacturerSidebarCounts = (
    enabledPages: Set<string>,
): Record<ManufacturerOrderScreenWithCount, number | null> => {
    const sidebarRefetchTimeout = 5 * 60 * 1000;

    // new stuff
    const v2pages = [...enabledPages].filter(p => p.endsWith('_V2') || p.includes('Intake'));
    const { data: { counts: sidebarCounts } = {} } = useLabPortalSidebarCountsQuery({
        variables: { pages: v2pages },
        pollInterval: sidebarRefetchTimeout,
    });

    return {
        New_V2: sidebarCounts?.New_V2 ?? null,
        Intake: sidebarCounts?.Intake ?? null,
        Nested_Intake: sidebarCounts?.Nested_Intake ?? null,
        Design_V2: sidebarCounts?.Design_V2 ?? null,
        Updates_V2: sidebarCounts?.Updates_V2 ?? null,
        Expedite_V2: sidebarCounts?.Expedite_V2 ?? null,
        OnHold_V2: sidebarCounts?.OnHold_V2 ?? null,
        Returns_V2: sidebarCounts?.Returns_V2 ?? null,
    };
};

interface ManufacturerSidebarProps {
    enabledPages: Set<string>;
}

// EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
// eslint-disable-next-line sonarjs/cognitive-complexity, max-lines-per-function
export const ManufacturerSidebar: React.FC<ManufacturerSidebarProps> = props => {
    const { enabledPages } = props;
    const { sidebarOpen } = usePracticeAppSelector(s => s.ui);
    const isMobile = useScreenIsMobile();
    const history = useHistory();
    const sidebarCounts = useManufacturerSidebarCounts(enabledPages);
    const { lab_does_design } = useManufacturerOptions();
    const activeScreen = useManufacturerSelector(s => s.screen.screenSelection);
    const setScreen = useManufacturerAction('SET_SCREEN');

    const mainItems: NavigationEntry[] = [];
    // 'Overview',
    mainItems.push({
        onClick: () => {
            if (activeScreen === 'Overview') {
                return;
            }
            // trigger a navigation event for analytics tracking
            history.push(`/`, { screen: `lab:Overview` });
            setScreen({ screen: 'Overview' });
        },
        title: 'Overview',
        active: activeScreen === 'Overview',
        iconName: 'HomeIcon',
        isFirstSectionItem: false,
        itemCount: undefined,
    });
    // 'New_V2',
    if (enabledPages.has('New_V2')) {
        // TODO: EPDSCM-618 added a screen but need to stop relying on state in here, values should be passed in
        mainItems.push({
            onClick: () => {
                if (activeScreen === 'New_V2') {
                    return;
                }
                // trigger a navigation event for analytics tracking
                history.push('/new/with-design', { screen: `lab:New_V2` });
                setScreen({ screen: 'New_V2' });
            },
            title: 'New',
            active: activeScreen === 'New_V2',
            iconName: 'OrderPaperIcon',
            isFirstSectionItem: false,
            itemCount: sidebarCounts['New_V2'] ?? undefined,
        });
    }
    // 'Intake',
    if (enabledPages.has('Intake')) {
        mainItems.push({
            onClick: () => {
                if (activeScreen === 'Intake') {
                    return;
                }
                // trigger a navigation event for analytics tracking
                history.push('/intake/with-design', { screen: `lab:Intake` });
                setScreen({ screen: 'Intake' });
            },
            title: 'Intake',
            active: activeScreen === 'Intake',
            iconName: 'OrderPaperIcon',
            isFirstSectionItem: false,
            itemCount: sidebarCounts['Intake'] ?? undefined,
        });
    }
    // 'Nested Intake',
    if (enabledPages.has('Nested_Intake')) {
        mainItems.push({
            onClick: () => {
                if (activeScreen === 'Nested_Intake') {
                    return;
                }
                // trigger a navigation event for analytics tracking
                history.push('/nested-intake/auto-nested', { screen: `lab:Nested_Intake` });
                setScreen({ screen: 'Nested_Intake' });
            },
            title: 'Nested Intake',
            active: activeScreen === 'Nested_Intake',
            iconName: 'OrderPaperIcon',
            isFirstSectionItem: false,
            itemCount: sidebarCounts['Nested_Intake'] ?? undefined,
        });
    }
    // 'Nesting',
    if (enabledPages.has('Nesting')) {
        mainItems.push({
            onClick: () => {
                if (activeScreen === 'Nesting') {
                    return;
                }
                // trigger a navigation event for analytics tracking
                history.push('/nesting', { screen: `lab:Nesting` });
                setScreen({ screen: 'Nesting' });
            },
            title: 'Nesting',
            active: activeScreen === 'Nesting',
            iconName: 'CloudUploadIcon',
            isFirstSectionItem: false,
            itemCount: undefined,
        });
    }
    // 'Design_V2',
    if (enabledPages.has('Design_V2') && lab_does_design) {
        mainItems.push({
            onClick: () => {
                if (activeScreen === 'Design_V2') {
                    return;
                }
                // trigger a navigation event for analytics tracking
                history.push(`/design`, { screen: `lab:Design_V2` });
                setScreen({ screen: 'Design_V2' });
            },
            title: 'Design V2',
            active: activeScreen === 'Design_V2',
            iconName: 'DesignIcon',
            isFirstSectionItem: false,
            itemCount: sidebarCounts['Design_V2'] ?? undefined,
        });
    }
    // 'Updates_V2',
    if (enabledPages.has('Updates_V2')) {
        mainItems.push({
            onClick: () => {
                if (activeScreen === 'Updates_V2') {
                    return;
                }
                // trigger a navigation event for analytics tracking
                history.push(`/updates/pull-from-floor`, { screen: `lab:Updates_V2` });
                setScreen({ screen: 'Updates_V2' });
            },
            title: 'Updates V2',
            active: activeScreen === 'Updates_V2',
            iconName: 'WarningIcon',
            isFirstSectionItem: false,
            itemCount: sidebarCounts['Updates_V2'] ?? undefined,
        });
    }
    // 'Expedite_V2',
    if (enabledPages.has('Expedite_V2')) {
        mainItems.push({
            onClick: () => {
                if (activeScreen === 'Expedite_V2') {
                    return;
                }
                // trigger a navigation event for analytics tracking
                history.push(`/ship-today/late`, { screen: `lab:Expedite_V2` });
                setScreen({ screen: 'Expedite_V2' });
            },
            title: 'Ship Today',
            active: activeScreen === 'Expedite_V2',
            iconName: 'BoxIcon',
            isFirstSectionItem: false,
            itemCount: sidebarCounts['Expedite_V2'] ?? undefined,
        });
    }
    // 'OnHold_V2',
    if (enabledPages.has('OnHold_V2')) {
        // TODO: EPDSCM-618 added a screen but need to stop relying on state in here, values should be passed in
        mainItems.push({
            onClick: () => {
                if (activeScreen === 'OnHold_V2') {
                    return;
                }
                // trigger a navigation event for analytics tracking
                history.push('/on-hold', { screen: `lab:OnHold_V2` });
                setScreen({ screen: 'OnHold_V2' });
            },
            title: 'On Hold',
            active: activeScreen === 'OnHold_V2',
            iconName: 'StopIcon',
            isFirstSectionItem: false,
            itemCount: sidebarCounts['OnHold_V2'] ?? undefined,
        });
    }
    // 'Returns V2',
    if (enabledPages.has('Returns_V2')) {
        mainItems.push({
            onClick: () => {
                if (activeScreen === 'Returns_V2') {
                    return;
                }
                // trigger a navigation event for analytics tracking
                history.push(`/returns/pending`, { screen: `lab:Returns_V2` });
                setScreen({ screen: 'Returns_V2' });
            },
            title: 'Returns V2',
            active: activeScreen === 'Returns_V2',
            iconName: 'BoxIcon',
            isFirstSectionItem: false,
            itemCount: sidebarCounts['Returns_V2'] ?? undefined,
        });
    }

    const bottomItems = useBottomItems(enabledPages);

    return (
        <>
            <Navigation
                collapsible
                initialCollapsed={false}
                mainItems={mainItems}
                bottomItems={bottomItems}
                TemporaryMode={isMobile ? { active: isMobile, open: sidebarOpen } : undefined}
            />
            <PartnerOrdersSpotlightSearch />
            <StackCodeSpotlightSearch />
            <QRCodeScannerSpotlight />
        </>
    );
};
