import { useMutation } from '@apollo/client';
import { graphql, type RecordInternalRemakeMutationVariables } from '@orthly/graphql-inline-react';
import { getDepartmentOptionsForLab, getStageOptionsForDepartment } from '@orthly/shared-types';
import type { SimpleSelectOption } from '@orthly/ui';
import { SimpleInput } from '@orthly/ui';
import { SimpleDateTimePicker, RootActionDialog, SimpleSelect, useRootActionCommand } from '@orthly/ui';
import { Text, styled, Button } from '@orthly/ui-primitives';
import dayjs from 'dayjs';
import React from 'react';

const LabRecordInternalRemake_Mutation = graphql(`
    mutation RecordInternalRemake(
        $causedByStage: ManufacturingStageInputDTO!
        $labOrderId: String!
        $requiresRestartFromStage: ManufacturingStageInputDTO!
        $reason: String
        $newLmsOrderId: String
        $timestampOverride: DateTime
    ) {
        recordInternalRemake(
            causedByStage: $causedByStage
            labOrderId: $labOrderId
            requiresRestartFromStage: $requiresRestartFromStage
            reason: $reason
            newLmsOrderId: $newLmsOrderId
            timestampOverride: $timestampOverride
        )
    }
`);

/**
 * Represents the department and stage selection in the manufacturing process
 */
type DepartmentStageInfo = {
    departmentId: string;
    stageId: string;
};

/**
 * Props for the department/stage selector component
 */
interface DeptStationSelectorProps {
    mfgId: string;
    onSelect: (selection: DepartmentStageInfo) => void;
    selectorTitle: string;
    selectedDept: string;
    selectedStage: string;
    validationError?: string;
}

/**
 * Props for the timestamp override picker component
 */
interface InternalRemakeTimestampOverridePickerProps {
    onChange: (date: Date | null) => void;
    remakeTimestamp: Date | null;
}

interface InternalRemakeReasonNoteInputProps {
    onChange: (reason: string | undefined) => void;
    reason: string | undefined;
}

interface InternalRemakeLabtracIdInputProps {
    onChange: (labtracId: string | undefined) => void;
    labtracId: string | undefined;
    validationError?: string;
}

/**
 * Props for the main internal remake modal component
 */
interface MarkInternalRemakeModalProps {
    mfgId: string;
    labOrderId: string;
}

/**
 * Validation errors for the internal remake form
 */
interface RecordInternalRemakeFormError {
    causedBy?: string;
    restartFrom?: string;
    newLabtracId?: string;
}

const StyledForm = styled('div')({
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});

const StyledFormGroup = styled('div')({
    padding: '5px',
    width: '100%',
});

/**
 * Retrieves and sorts stage options for a given department
 * @param mfgId - Manufacturing ID
 * @param departmentId - Department ID
 * @returns Sorted array of stage options
 */
function getStageOptionsForDept(mfgId: string | undefined, departmentId: string | undefined): SimpleSelectOption[] {
    const stages = getStageOptionsForDepartment(departmentId, mfgId);
    const opts = stages.map(stage => ({ label: stage, value: stage }));
    return opts.sort((a, b) => a.label.localeCompare(b.label));
}

/**
 * Retrieves and sorts department options for a given manufacturing ID
 * @param mfgId - Manufacturing ID
 * @returns Sorted array of department options
 */
function getDepartmentOptions(mfgId: string | undefined): SimpleSelectOption[] {
    const nameById = getDepartmentOptionsForLab(mfgId);
    const entries = Object.entries(nameById);
    const opts = entries.map(([id, name]) => ({ label: name, value: id }));
    return opts.sort((a, b) => a.label.localeCompare(b.label));
}

/**
 * Validates the internal remake form inputs
 * @param causedBy - Department and stage that caused the remake
 * @param restartFrom - Department and stage to restart from
 * @param newLabtracId - Optional new Labtrac ID for stage 2 remakes
 * @returns Object containing validation errors, if any
 */
function validateInternalRemakeForm(
    causedBy: DepartmentStageInfo,
    restartFrom: DepartmentStageInfo,
    newLabtracId: string | undefined,
): RecordInternalRemakeFormError {
    const errors: RecordInternalRemakeFormError = {};

    if (!causedBy.departmentId || !causedBy.stageId) {
        errors.causedBy = 'Please select both department and stage for remake cause';
    }

    if (!restartFrom.departmentId || !restartFrom.stageId) {
        errors.restartFrom = 'Please select both department and stage for restart from';
    }

    if ((causedBy.departmentId === 'DDC-IR' || restartFrom.departmentId === 'DDC-IR') && !newLabtracId) {
        errors.newLabtracId = 'Stage 2 remakes require a new Labtrac Case Number';
    }

    if (newLabtracId && (causedBy.departmentId !== 'DDC-IR' || restartFrom.departmentId !== 'DDC-IR')) {
        errors.newLabtracId = 'New Labtrac Case Number is only required for Stage 2 remakes';
    }

    return errors;
}

function getSanitizedLabtracId(newLabtracOrderId?: string) {
    if (!newLabtracOrderId) {
        return undefined;
    }
    const trimmedLabtracId = newLabtracOrderId.trim();
    const withoutPrefix = trimmedLabtracId.replace(/^CN[-\s]?/, '');
    return withoutPrefix;
}

/**
 * Component for selecting department and stage information
 */
const DeptStationSelector: React.VFC<DeptStationSelectorProps> = ({
    mfgId,
    onSelect,
    selectorTitle,
    selectedDept,
    selectedStage,
    validationError,
}) => {
    const departmentOptions = getDepartmentOptions(mfgId);
    const stageOptions = selectedDept ? getStageOptionsForDept(mfgId, selectedDept) : [];

    const handleDepartmentChange = (deptId?: string) => {
        const selectedDeptId = deptId ?? '';
        onSelect({ departmentId: selectedDeptId, stageId: '' });
    };

    const handleStageChange = (stageId?: string) => {
        const selectedStageId = stageId ?? '';
        onSelect({ departmentId: selectedDept, stageId: selectedStageId });
    };

    return (
        <StyledForm>
            <StyledFormGroup>
                <Text>{selectorTitle}</Text>
            </StyledFormGroup>
            {validationError && (
                <StyledFormGroup>
                    <Text color={'REVIEW_RED'}>{validationError}</Text>
                </StyledFormGroup>
            )}
            <StyledFormGroup>
                <SimpleSelect
                    label={'Department'}
                    onChange={handleDepartmentChange}
                    options={departmentOptions}
                    value={selectedDept}
                />
            </StyledFormGroup>
            {selectedDept !== '' && stageOptions.length > 0 && (
                <StyledFormGroup>
                    <SimpleSelect
                        label={'Stage'}
                        onChange={handleStageChange}
                        options={stageOptions}
                        value={selectedStage}
                    />
                </StyledFormGroup>
            )}
        </StyledForm>
    );
};

/**
 * Component for selecting an optional timestamp override for the internal remake
 */
const InternalRemakeTimestampOverridePicker: React.VFC<InternalRemakeTimestampOverridePickerProps> = ({
    onChange,
    remakeTimestamp,
}) => {
    return (
        <StyledForm>
            <StyledFormGroup>
                <Text>Set the IR timestamp to the past (Optional)</Text>
            </StyledFormGroup>
            <StyledFormGroup>
                <SimpleDateTimePicker
                    label={'Leave blank for current time'}
                    value={remakeTimestamp}
                    onChange={onChange}
                    minDate={dayjs().subtract(20, 'days')} // Arbitrary min date
                    maxDate={dayjs()} // IR cannot be in the future
                />
            </StyledFormGroup>
        </StyledForm>
    );
};

const InternalRemakeReasonNoteInput: React.VFC<InternalRemakeReasonNoteInputProps> = ({ onChange, reason }) => {
    return (
        <StyledForm>
            <StyledFormGroup>
                <Text>Remake Reason Notes (Optional)</Text>
            </StyledFormGroup>
            <StyledFormGroup>
                <SimpleInput
                    fullWidth
                    label={'E.g. Chipped tooth in singulation..'}
                    value={reason}
                    onChange={v => onChange(v)}
                />
            </StyledFormGroup>
        </StyledForm>
    );
};

const InternalRemakeLabtracIdInput: React.VFC<InternalRemakeLabtracIdInputProps> = ({
    onChange,
    labtracId,
    validationError,
}) => {
    return (
        <StyledForm>
            <StyledFormGroup>
                <Text>Set the new Labtrac Case Number (STAGE 2 ONLY)</Text>
            </StyledFormGroup>
            {validationError && (
                <StyledFormGroup>
                    <Text color={'REVIEW_RED'}>{validationError}</Text>
                </StyledFormGroup>
            )}
            <StyledFormGroup>
                <SimpleInput fullWidth label={'CN-XXXXXX'} value={labtracId} onChange={v => onChange(v)} />
            </StyledFormGroup>
        </StyledForm>
    );
};

/**
 * Main form component for recording an internal remake for an order in fabrication.
 * Allows selection of cause department/stage, restart department/stage, and optional timestamp.
 * The timestamp override is for cases where someone forgot to record the IR in time in the portal and has to backdate it.
 */
export const RecordInternalRemakeModal: React.VFC<MarkInternalRemakeModalProps> = ({ mfgId, labOrderId }) => {
    const [causedBy, setCausedBy] = React.useState<DepartmentStageInfo>({ departmentId: '', stageId: '' });
    const [restartFrom, setRestartFrom] = React.useState<DepartmentStageInfo>({ departmentId: '', stageId: '' });
    const [remakeTimestamp, setRemakeTimestamp] = React.useState<Date | null>(null);
    const [newLabtracOrderId, setNewLabtracOrderId] = React.useState<string | undefined>(undefined);
    const [reason, setReason] = React.useState<string | undefined>(undefined);
    const [open, setOpen] = React.useState<boolean>(false);
    const [errors, setErrors] = React.useState<RecordInternalRemakeFormError>({});
    const recordInternalRemakeMutation = useMutation(LabRecordInternalRemake_Mutation);
    const { submit: submitMutation, submitting } = useRootActionCommand(recordInternalRemakeMutation, {
        onSuccess: () => {},
        successMessage: 'Internal Remake Recorded!',
    });

    const handleSubmit = () => {
        const newLabtracId = getSanitizedLabtracId(newLabtracOrderId);
        const validationErrors = validateInternalRemakeForm(causedBy, restartFrom, newLabtracId);
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            return submitMutation({
                causedByStage: { deptId: causedBy.departmentId, stageId: causedBy.stageId },
                labOrderId: labOrderId,
                requiresRestartFromStage: { deptId: restartFrom.departmentId, stageId: restartFrom.stageId },
                reason: 'test reason',
                newLmsOrderId: newLabtracId,
                timestampOverride: remakeTimestamp?.toISOString() ?? null,
            } satisfies RecordInternalRemakeMutationVariables);
        }
    };

    return (
        <RootActionDialog
            title={'Record IR'}
            loading={false}
            open={open}
            setOpen={setOpen}
            style={{ padding: 0 }}
            content={
                <>
                    <DeptStationSelector
                        mfgId={mfgId}
                        onSelect={remakeCausedBy => setCausedBy(remakeCausedBy)}
                        selectorTitle={'Remake Cause (Select DDC for Stage 2)'}
                        selectedDept={causedBy.departmentId}
                        selectedStage={causedBy.stageId}
                        validationError={errors.causedBy}
                    />
                    <DeptStationSelector
                        mfgId={mfgId}
                        onSelect={restartFrom => setRestartFrom(restartFrom)}
                        selectorTitle={'Restart from Stage (Select DDC for Stage 2)'}
                        selectedDept={restartFrom.departmentId}
                        selectedStage={restartFrom.stageId}
                        validationError={errors.restartFrom}
                    />
                    <InternalRemakeLabtracIdInput
                        onChange={setNewLabtracOrderId}
                        labtracId={newLabtracOrderId}
                        validationError={errors.newLabtracId}
                    />
                    <InternalRemakeReasonNoteInput onChange={setReason} reason={reason} />
                    <InternalRemakeTimestampOverridePicker
                        onChange={setRemakeTimestamp}
                        remakeTimestamp={remakeTimestamp}
                    />
                    <Button disabled={submitting} onClick={() => handleSubmit()} variant={'primary'}>
                        Record IR
                    </Button>
                </>
            }
            CustomButton={buttonProps => (
                <Button {...buttonProps} fullWidth={false} variant={'text'} startIcon={'RefreshIcon'}>
                    Record IR
                </Button>
            )}
        />
    );
};
